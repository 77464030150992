import { GenericPopUpClose } from "./genericPopup.js";
import { removeAll, resetTimerOnInteraction, resetTimerOnClick } from "./inactivityMonitor.js"


const display = () => {
    GenericPopUpClose();

    $.ajax({
        type: 'GET',
        url: `/account/inactivity-prompt`,
        cache: false,
        success: function(response) {
            window.site.body.append(response);
            $('body').one('click', function() {
                $('.m-popup-new, .popupFade-new').remove()
            });
        }
        // Error condition here to be addressed in ST-4624
    });

    removeAll();
    resetTimerOnClick();

    window.site.doc.on('click', onClose);
}

const onClose = () => {
    removeAll();
    resetTimerOnInteraction();
    GenericPopUpClose();
}

export default display;
export { display };
